//BASE URLS
const apiEnvs = {
	//API environment constants
	ApiUrl: process.env.REACT_APP_API_URL,
	BaseUrl: process.env.REACT_APP_PUBLIC_URL,
}

//DEFAULT COLORS
const defaultColors = {
	PowerSpectrumClickColor: "#2070D6",
	PowerSpectrumClickOneColor: "pink",
	PowerSpectrumClickTwoColor: "orange",
	PowerSpectrumClickThreeColor: "purple",
	ResonanceClickColor: "#fb0202",
	ResonanceClickOneColor: "pink",
	ResonanceClickTwoColor: "orange",
	ResonanceClickThreeColor: "purple",
	PowerSpectrumPlotDefaultColor: "#2070D6",
	ResonancePlotDefaultColor: '#fb0202',
	BackgroundColorDefault: "#000000",
	PitchPlotDefaultColor: "#2070D6",
	LoudnessPlotDefaultColor: "#fffdd0",
	ClarityPlotDefaultColor: "#0000FF",
	BrightnessPlotDefaultColor: "#0000FF",
	FrequencyLinePlotColor: "#0ed200",
	ResonanceMarkerSpectrogram: '#00FF00',
	Spectrogram1DefaultColor: "#BD2D60",
	Spectrogram2DefaultColor: "#D37263",
	Spectrogram3DefaultColor: "#E9B960",
	Spectrogram4DefaultColor: "#FFFF63",
	Spectrogram5DefaultColor: "#BEFF8A",
	Spectrogram6DefaultColor: "#7FFEB0",
	Spectrogram7DefaultColor: "#3FFC29",
	Spectrogram8DefaultColor: "#02FCFD",
	Spectrogram9DefaultColor: "#03C7F0",
	Spectrogram10DefaultColor: "#0097E9",
	Spectrogram11DefaultColor: "#0364DB",
	Spectrogram12DefaultColor: "#0330D0",
	TimeDomainDefaultColor: "#fffdd0",
	SpectrogramSignalDefaultColor: "#0000FF",
}
//DIFFERENT DATE FORMATS
const dateFormats = {
	DateFormat: 'DD-MMM-YYYY',
	DateTimeFormat: 'DD-MMM-YYYY HH:mm a',
}
//ALL SUCCESS/ERROR CODES
const codes = {
	SUCCESS_CODE: "SUCCESS",
	FAIL: "FAIL",
	TOKEN_INVALID: 418,
	SESSION_TIMEOUT: 420,
	FAIL_CODE: 400,
}
//ADDED RESPONSE MESSAGES BASED ON CODES FOR INTERNATIONALIZATION PURPOSES
const responseMessages = {
	SUCCESS_REGISTRATION: "Successfully registered!",
  SUCCESS_LOGIN: "Successfully logged in!",
  USER_LOGGED_OUT: "User logged out successfully.",
  SUBSCRIBER_UPDATED: "Subscriber updated successfully.",
  USER_DETAILS_UPDATED: "Successfully updated user details.",
  USER_DELETED: "User deleted successfully.",
  USER_DETAILS_FETCHED: "User details fetched successfully.",
  TRAINER_VALID: "Trainer is valid.",
  PASSWORD_RESET_SUCCESS: "Password has been reset successfully.",
  TOKEN_VALID: "Token is valid.",
  RESET_PASSWORD_LINK_SENT: "Reset password link has been sent to your email id.",
  LIBRARY_ADDED: "Library added successfully.",
  FILE_RENAMED: "File renamed successfully.",
  FILE_DELETED: "File deleted successfully.",
  EXAM_CREATED: "Exam created successfully.",
  USER_TYPES_FETCHED_SUCCESSFULLY: "User types fetched successfully.",
  UPDATED_SUCCESSFULLY: "Updated successfully.",
  PERMISSION_ADDED: "Permission added.",
  USER_PASSWORD_UPDATED: "User password updated successfully.",
  UPLOADED_SUCCESSFULLY: "Successfully uploaded.",
  SUBSCRIPTION_CANCELLED: "Subscription cancelled.",
  BACKGROUND_IMAGE_UPDATED: "Background image updated successfully",
  COUNTS_FETCHED: "Counts fetched successfully",
  IMAGES_FETCHED: "Images fetched successfully",
  USER_ARCHIVED: "User archived successfully.",
  USERS_FETCHED: "Users fetched successfully.",
  NOTIFICATION_CREATED: "Notification created successfully!",
  USER_NOTIFICATIONS_FETCHED: "User notifications fetched successfully.",
  NO_NEW_NOTIFICATIONS: "There are no new notifications at the moment. Check back later for updates!",
  MARKED_AS_READ: "You've successfully marked the notification as read.",
  USER_ALREADY_EXISTS: "User already exists.",
  USER_ALREADY_EXISTS_LOGIN_AGAIN: "User already exists. Please Log in.",
  FAILED_TO_REGISTER_USER: "Failed to register user.",
  USER_LOGIN_FAILED: "User login failed.",
  USER_LOGOUT_FAILED: "User logout failed.",
  INVALID_LOGIN_DETAILS: "Invalid login details.",
  SUBSCRIBER_UPDATE_FAILED: "Subscriber update failed.",
  MISSING_REQUIRED_FIELDS: "Missing required fields.",
  FAILED_TO_UPDATE_USER_DETAILS: "Failed to update user details.",
  FAILED_TO_DELETE_USER: "Failed to delete user.",
  FAILED_TO_FETCH_USER_DETAILS: "Failed to fetch user details.",
  INVALID_USER_ROLE: "Please provide a valid user role.",
  INVALID_EMAIL: "Invalid email.",
  INVALID_USER_DETAILS: "Please provide valid user details.",
  INVALID_USER_ID: "Invalid user id.",
  INVALID_PHONE_NUMBER: "Please provide a valid phone number.",
  PERMISSION_DENIED: "Permission denied.",
  INVALID_PLAN_DETAILS: "Invalid plan details.",
  INVALID_TRAINER: "Invalid trainer.",
  INVALID_TOKEN: "Invalid token.",
  USER_NOT_FOUND_LOGIN_AGAIN: "User not found. Please login again.",
  SESSION_EXPIRED: "Your previous session has expired. Please login again.",
  TOKEN_MISSING: "Token missing in the authorization header.",
  AUTHENTICATION_FAILED: "Failed to authenticate token. Please login to continue.",
  UNAUTHORIZED_FUNCTIONALITY: "You are not authorized to use this functionality.",
  EMAIL_NOT_EXIST: "Email does not exist.",
  NO_SESSION: "User does not have a session. Please login again.",
  USER_COURSE_ID_MISSING: "Either user id or course id must be passed.",
  FAILED_TO_GET_COURSE_DETAILS: "Failed to get course details.",
  FAILED_TO_GET_USER_DETAILS: "Failed to get user details.",
  FAILED_TO_GET_SYSTEM_USER_DETAILS: "Failed to get system user details.",
  PASSWORD_RESET_UNAUTHORIZED: "You are not authorized to reset passwords for users of this course.",
  USER_PASSWORD_RESET_UNAUTHORIZED: "You are not authorized to reset this user's password.",
  TOKEN_SIGNATURE_EXPIRED: "Signature expired. Please log in again.",
  INVALID_TOKEN_LOGIN_AGAIN: "Invalid token. Please log in again.",
  RESET_KEYS_DO_NOT_MATCH: "Reset Keys do not match.",
  INVALID_PASSWORD_FORMAT: "Invalid password. Minimum length is 8 characters, should contain 1 number, 1 uppercase, 1 lowercase, and 1 special character.",
  PASSWORD_DOES_NOT_MATCH: "Password does not match.",
  PASSWORD_FIELD_EMPTY: "Password field is empty.",
  INVALID_REPLY_TO_ADDRESS: "Please send valid Reply To addresses.",
  INVALID_LINK: "Invalid link.",
  EMAIL_ALREADY_EXISTS: "Email already exists.",
  PROVIDE_RECIPIENTS: "Please provide recipients.",
  FILE_ALREADY_EXISTS: "File already exists.",
  ADD_LIBRARY_FAILED: "Add Library Failed.",
  FILE_RENAME_FAILED: "File rename failed.",
  FILE_DELETE_FAILED: "File delete failed.",
  FILE_NOT_PRESENT_IN_DIRECTORY: "File not present in the directory.",
  FILE_NOT_PRESENT: "File not present.",
  NAME_ALREADY_PRESENT: "Name already present.",
  USER_NOT_FOUND: "User not found.",
  EXAM_ID_NOT_FOUND: "Exam id not found.",
  DELETE_FAILED: "Delete failed.",
  USER_ID_NOT_FOUND: "User id not found.",
  PREFERENCES_NOT_FOUND: "Preferences not found.",
  ID_DOES_NOT_EXIST: "Id does not exist.",
  UPDATE_FAILED: "Update failed.",
  USER_SETTING_NOT_FOUND: "User setting not found for the given user id.",
  USER_NOT_FOUND_FOR_ID: "User not found for the given user id.",
  EXERCISE_SETTINGS_NOT_FOUND: "Exercise settings not found for the given username.",
  INVALID_VIEW_POSITION: "Invalid view or position.",
  INVALID_TIMEZONE: "Invalid timezone.",
  FETCHING_DATA_FAILED: "Fetching data failed.",
  PLANS_NOT_FOUND: "Plans not found.",
  FETCH_FAILED: "Fetch failed.",
  OLD_PASSWORD_INCORRECT: "Old password is incorrect.",
  PASSWORD_UPDATE_FAILED: "Password update failed.",
  PLANS_NOT_PRESENT: "Plans are not present.",
  FAILED_TO_FETCH_PLANS: "Failed to fetch plans.",
  PLAN_ID_EMPTY: "Plan id is empty.",
  INTERVAL_EMPTY: "Interval is empty.",
  PLAN_NOT_FOUND: "Plan not found.",
  CHECKOUT_FAILED: "Checkout failed.",
  EMAIL_DOES_NOT_EXIST_REGISTER: "Email does not exist, please register.",
  PASSWORD_INCORRECT: "Password incorrect.",
  INVALID_USER: "Invalid user.",
  NAME_ALREADY_EXISTS: "Name already exists.",
  INVALID_DATE_OR_NAME: "Enter valid date or name.",
  FAILED_TO_FETCH: "Failed to fetch.",
  SUBSCRIPTION_EXPIRED: "Subscription expired",
  ATTACHMENT_LIMIT_EXCEEDED: "Attachment limit exceeded",
  INVALID_EXTENSION: "Invalid extension",
  INVALID_ATTACHMENT_FILE_PATH: "Invalid attachment file path",
  USER_SETTING_NOT_FOUND_FOR_ID: "User setting not found for the given user id.",
  FAILED_TO_FETCH_USERS: "Failed to fetch users.",
  FAILED_TO_CREATE_NOTIFICATION: "Failed to create a notification.",
  NOTIFICATIONS_RETRIEVAL_FAILED: "We couldn't retrieve your notifications. Please try again later.",
  MARKING_NOTIFICATION_READ_FAILED: "Oops! Something went wrong while marking the notification as read. Please try again later.",
  INVALID_NOTIFICATION_DETAILS: "Invalid notification details.",
  PLEASE_PROCEED_TO_PAYMENT: "Please proceed to payment",
  FAILED_TO_ARCHIVE_USER: "Failed to archive user.",
  SUBSCRIBERS_NOT_AVAILABLE: "Subscribers not available in this plan.",
	INVALID_PROTOCOL: "Invalid protocol",
	FILENAME_NOT_EXIST: "Filename does not exist",
	FILENAME_EXIST: "Filename exist",
	INVALID_OLD_FILENAME: "Invalid old file name",
	FILE_UPLOAD_FAILED: "File uploads failed",
	GIVEN_FILENAME_NOT_EXIST: "File with the given filename does not exist",
	FILESIZE_FETCH_FAILED: "Failed to fetch file size",
	STORAGE_LIMIT_EXCEEDED: "Storage limit exceeded",
}
//RESPONSE MESSAGES BASED ON CODES
const responses = {
	200: responseMessages.SUCCESS_REGISTRATION,
	201: responseMessages.SUCCESS_LOGIN,
	202: responseMessages.USER_LOGGED_OUT,
	203: responseMessages.SUBSCRIBER_UPDATED,
	204: responseMessages.USER_DETAILS_UPDATED,
	205: responseMessages.USER_DELETED,
	206: responseMessages.USER_DETAILS_FETCHED,
	207: responseMessages.TRAINER_VALID,
	208: responseMessages.PASSWORD_RESET_SUCCESS,
	209: responseMessages.TOKEN_VALID,
	210: responseMessages.RESET_PASSWORD_LINK_SENT,
	211: responseMessages.LIBRARY_ADDED,
	212: responseMessages.FILE_RENAMED,
	213: responseMessages.FILE_DELETED,
	214: responseMessages.EXAM_CREATED,
	215: responseMessages.USER_TYPES_FETCHED_SUCCESSFULLY,
	216: responseMessages.UPDATED_SUCCESSFULLY,
	217: responseMessages.PERMISSION_ADDED,
	218: responseMessages.USER_PASSWORD_UPDATED,
	219: responseMessages.UPLOADED_SUCCESSFULLY,
	220: responseMessages.SUBSCRIPTION_CANCELLED,
	221: responseMessages.BACKGROUND_IMAGE_UPDATED,
	222: responseMessages.COUNTS_FETCHED,
	223: responseMessages.IMAGES_FETCHED,
	251: responseMessages.USER_ARCHIVED,
	252: responseMessages.USERS_FETCHED,
	253: responseMessages.NOTIFICATION_CREATED,
	254: responseMessages.USER_NOTIFICATIONS_FETCHED,
	255: responseMessages.NO_NEW_NOTIFICATIONS,
	256: responseMessages.MARKED_AS_READ,
	400: responseMessages.USER_ALREADY_EXISTS_LOGIN_AGAIN,
	401: responseMessages.FAILED_TO_REGISTER_USER,
	402: responseMessages.USER_LOGIN_FAILED,
	403: responseMessages.USER_LOGOUT_FAILED,
	404: responseMessages.INVALID_LOGIN_DETAILS,
	405: responseMessages.SUBSCRIBER_UPDATE_FAILED,
	406: responseMessages.MISSING_REQUIRED_FIELDS,
	407: responseMessages.FAILED_TO_UPDATE_USER_DETAILS,
	408: responseMessages.FAILED_TO_DELETE_USER,
	409: responseMessages.FAILED_TO_FETCH_USER_DETAILS,
	410: responseMessages.INVALID_USER_ROLE,
	411: responseMessages.INVALID_EMAIL,
	412: responseMessages.INVALID_USER_DETAILS,
	413: responseMessages.INVALID_USER_ID,
	414: responseMessages.INVALID_PHONE_NUMBER,
	415: responseMessages.PERMISSION_DENIED,
	416: responseMessages.INVALID_PLAN_DETAILS,
	417: responseMessages.INVALID_TRAINER,
	418: responseMessages.INVALID_TOKEN,
	419: responseMessages.USER_NOT_FOUND_LOGIN_AGAIN,
	420: responseMessages.SESSION_EXPIRED,
	421: responseMessages.TOKEN_MISSING,
	422: responseMessages.AUTHENTICATION_FAILED,
	423: responseMessages.UNAUTHORIZED_FUNCTIONALITY,
	424: responseMessages.EMAIL_NOT_EXIST,
	425: responseMessages.NO_SESSION,
	426: responseMessages.USER_COURSE_ID_MISSING,
	427: responseMessages.FAILED_TO_GET_COURSE_DETAILS,
	428: responseMessages.FAILED_TO_GET_USER_DETAILS,
	429: responseMessages.FAILED_TO_GET_SYSTEM_USER_DETAILS,
	430: responseMessages.PASSWORD_RESET_UNAUTHORIZED,
	431: responseMessages.USER_PASSWORD_RESET_UNAUTHORIZED,
	432: responseMessages.TOKEN_SIGNATURE_EXPIRED,
	433: responseMessages.INVALID_TOKEN_LOGIN_AGAIN,
	434: responseMessages.RESET_KEYS_DO_NOT_MATCH,
	435: responseMessages.INVALID_PASSWORD_FORMAT,
	436: responseMessages.PASSWORD_DOES_NOT_MATCH,
	437: responseMessages.PASSWORD_FIELD_EMPTY,
	438: responseMessages.INVALID_REPLY_TO_ADDRESS,
	439: responseMessages.INVALID_LINK,
	440: responseMessages.EMAIL_ALREADY_EXISTS,
	441: responseMessages.PROVIDE_RECIPIENTS,
	442: responseMessages.FILE_ALREADY_EXISTS,
	443: responseMessages.ADD_LIBRARY_FAILED,
	444: responseMessages.FILE_RENAME_FAILED,
	445: responseMessages.FILE_DELETED,
	446: responseMessages.FILE_DELETE_FAILED,
	447: responseMessages.FILE_NOT_PRESENT_IN_DIRECTORY,
	448: responseMessages.FILE_NOT_PRESENT,
	449: responseMessages.NAME_ALREADY_PRESENT,
	450: responseMessages.USER_NOT_FOUND,
	451: responseMessages.EXAM_ID_NOT_FOUND,
	452: responseMessages.DELETE_FAILED,
	453: responseMessages.USER_ID_NOT_FOUND,
	454: responseMessages.PREFERENCES_NOT_FOUND,
	455: responseMessages.ID_DOES_NOT_EXIST,
	456: responseMessages.UPDATE_FAILED,
	457: responseMessages.USER_SETTING_NOT_FOUND_FOR_ID,
	458: responseMessages.USER_NOT_FOUND_FOR_ID,
	459: responseMessages.EXERCISE_SETTINGS_NOT_FOUND,
	460: responseMessages.INVALID_VIEW_POSITION,
	461: responseMessages.INVALID_TIMEZONE,
	462: responseMessages.FETCHING_DATA_FAILED,
	463: responseMessages.PLANS_NOT_FOUND,
	464: responseMessages.FETCH_FAILED,
	465: responseMessages.OLD_PASSWORD_INCORRECT,
	466: responseMessages.PASSWORD_UPDATE_FAILED,
	467: responseMessages.PLANS_NOT_PRESENT,
	468: responseMessages.FAILED_TO_FETCH_PLANS,
	469: responseMessages.PLAN_ID_EMPTY,
	470: responseMessages.INTERVAL_EMPTY,
	471: responseMessages.PLAN_NOT_FOUND,
	472: responseMessages.CHECKOUT_FAILED,
	473: responseMessages.EMAIL_DOES_NOT_EXIST_REGISTER,
	474: responseMessages.PASSWORD_INCORRECT,
	475: responseMessages.INVALID_USER,
	476: responseMessages.NAME_ALREADY_EXISTS,
	477: responseMessages.INVALID_DATE_OR_NAME,
	478: responseMessages.FAILED_TO_FETCH,
	479: responseMessages.SUBSCRIPTION_EXPIRED,
	480: responseMessages.ATTACHMENT_LIMIT_EXCEEDED,
	481: responseMessages.INVALID_EXTENSION,
	482: responseMessages.INVALID_ATTACHMENT_FILE_PATH,
	483: responseMessages.EXAM_ID_NOT_FOUND,
	484: responseMessages.USER_SETTING_NOT_FOUND_FOR_ID,
	485: responseMessages.FAILED_TO_ARCHIVE_USER,
	486: responseMessages.FAILED_TO_FETCH_USERS,
	487: responseMessages.USER_ALREADY_EXISTS,
	488: responseMessages.FAILED_TO_CREATE_NOTIFICATION,
	489: responseMessages.NOTIFICATIONS_RETRIEVAL_FAILED,
	490: responseMessages.MARKING_NOTIFICATION_READ_FAILED,
	491: responseMessages.INVALID_NOTIFICATION_DETAILS,
	510: responseMessages.PLEASE_PROCEED_TO_PAYMENT,
	514: responseMessages.INVALID_PROTOCOL,
	515: responseMessages.FILENAME_NOT_EXIST,
	516: responseMessages.FILENAME_EXIST,
	517: responseMessages.INVALID_OLD_FILENAME,
	518: responseMessages.FILE_UPLOAD_FAILED,
	520: responseMessages.GIVEN_FILENAME_NOT_EXIST,
	521: responseMessages.FILESIZE_FETCH_FAILED,
	522: responseMessages.STORAGE_LIMIT_EXCEEDED,
}
//ALL CONSTANTS
const constants = {
	PreferenceValues: "Preference Values",
	FrequencyMarker: "Frequency Markers",
	VoiceprintColors: "Voiceprint Colors",
	ReferenceValues: "Reference Values",
	SystemSettings: "System Settings",
	SetRecordingTime: "Set Recording Time",
	TakeTour: "Take a Tour",
	StoreEfpEmt: "Store EFP/EMT",
	Save: "Save",
	Continue: "Continue",
	RecallEfpEmt: "Recall EFP/EMT",
	EstillFigureProficiency: 'Estill Figure Proficiency',
	EstillMasterTrainer: 'Estill Master Trainer',
	Spectrogram: "Spectrogram",
	ProcessingSpectrogram: "Processing Spectrogram...Please Wait...",
	PitchAndSpl: "Pitch & SPL",
	PowerSpectrum: "Power Spectrum",
	Powerspect: "Power Spect",
	Resonance: "Resonance",
	Record: "Record",
	Stop: "Stop",
	Play: "Play",
	PlayFromInventory:"Play from Inventory",
	NextPage: "Next Page",
	PreviousPage: "Previous Page",
	Zoom: "Cancel Zoom",
	Download: "Download",
	SomethingWentWrong: "Something went wrong, please try again later.",
	SelectProtocolExerciseError: "Please select a protocol exercise.",
	FileUploadedSuccessfully: "File uploaded successfully.",
	ThemeUploadedSuccessfully: "Theme has been updated successfully.",
	ExamAddedSuccessfully: "Exam added successfully.",
	ExamDeletedSuccessfully: "Exam deleted successfully.",
	ExamExists: "Exam already exists.",
	FileNameRequired:"File name is required!",
	FileNameAlreadyExists:"File name already exists.",
	CantFindAudio: "Could not find audio.",
	NotWavFile: "is not a WAV file",
	NotPngJpgFile: "is not a PNG/JPG file",
	Upload: "Upload audio to server",
	Recall: "Recall audio from server",
	RecallSystem: "Recall audio from system",
	PitchTracker: "Pitch Tracker",
	SPLCalibration:"Calibrate Sound Level",
	ClearScreen:"Clear View(s)",
	LowAndHighPitchReference: "Low and High Pitch Reference",
	SetRange: "Set Range",
	TestRange: "Test Range",
	VvtiModalTitle: "Welcome to Virtual Voice Trainer",
	SubTitle: "Please select an exercise below.",
	WarmingUp: "Warming Up",
	PitchGlide: "Pitch Glide",
	ReductionTension: "Reduction Tension",
	PressedClear: "Pressed/Clear",
	SyllableRate: "Syllable Rate",
	StartingTheTone: "Starting the Tone",
	Glottal:"Glottal",
	Aspirate:"Aspirate",
	Smooth:"Smooth",
	ProjectingTheVoice: "Projecting the Voice",
	Brightness: "Brightness",
	Loudness: "Loudness",
	Exercises: "Exercises",
	Pitch: "Pitch",
	FileLabel:"File Name",
	ExerciseLabel: "Exercise",
	ExerciseOne: "Exercise One",
	ExerciseTwo: "Exercise Two",
	DeleteExercise: "Delete exercise?",
	ReducingNasality: "Reducing Nasality",
	Nasality: "Nasality",
	Male:"Male",
	Female:"Female",
	OpenPractice: "Open Practice",
	RecordOwnVoice: "Record Own Voice",
	PlayOwnVoice: "Play Own Voice",
	PlayOffTarget: "Play Off Target",
	PlayOnTarget: "Play On Target",
	SaveOwnVoice: "Save Own Voice",
	RecallOwnVoice: "Recall Own Voice",
	SelectNewExercice: "Select New Exercise",
	ChangeSettings: "Change Settings",
	Time: "Time (sec):",
	Frequency:"Frequency (Hz):",
	SignalLevel:"Intensity (dB): ",
	PitchFreq:"Freq/Pitch",
	StartTrim:"Start Trim:",
	EndTrim:"End Trim",
	Reset:"Reset",
	Ok:"OK",
	Cancel:"Cancel",
	GoBack:"Go Back",
	MinHnr:"Min HNR",
	MaxHnr:"Max HNR",
	AvgHnr:"Avg HNR",
	BrightnessInfo:"Train and measure brightness and projection in speech and song.",
	ClarityInfo:"Train and measure noise to harmonics ratio in speech and song.",
	LoudnessInfo:"Train and measure intensity in speaking and singing.",
	PitchInfo:"Train and measure range of inflection and melody in speech and song.",
	VoiceExecModalTitle:"Welcome to VoiceEXEC Personal Trainer",
	Engage:"Engage",
	PitchMelody: "Pitch/Melody",
	Excite:"Excite",
	Empathize:"Empathize",
	Clarity:"Clarity",
	Color:"Color",
	Convince:"Convince",
	Rate:"Rate",
	Diction:"Diction",
	ShowExScript:"Show Exercise Script",
	ExScript:"Exercise Script",
	PlayBalancedVoice:"Play Voice Example",
	PlaySavedRecording:"Play Saved Recording",
	Summary:"Summary",
	Single: "Single",
	Upper: "Upper",
	Lower: "Lower",
	Grid: "Grid",
	PeakHold: "Peak Hold",
	Clear: "Clear",
	SelectSoftware:"Choose the software you would like to use.",
	ChangeSoftware:"Change Software",
	Preferences:"Preferences",
	Help:"Help",
	Pointers:"Pointers",
	DefaultCursor:"Default Cursor",
	Crosshair:"Crosshair",
	Microphone:"It appears that your microphone is disabled. Check the browser setting to allow the use of a microphone. Refresh the webpage once microphone access is allowed.",
	MicrophonePopupTitle:"Your microphone isn't working.",
	VoiceNotSaved:"Voice is not recorded.",
	InventoryA: "Sample Exam",
	InventoryB: "Create Exam",
	WavFile: "WAVE File",
	ExercisePlaceHolder:"Your exercise script...",
	SelectExcercisePlaceHolder:"Select an Exercise",
	Waveform:"Waveform",
	Hz:"Hz",
	Db:"dB",
	SingleView:"Single View",
	On:"ON",
	Off:"OFF",
	SplitView:"Split View",
	Running:"running",
	Stopped:"stopped",
	ClickedItem:"Clicked the item",
	Gain:"Intensity Gain",
	Volume:"Volume",
	MaleVoice:"Male Voices",
	FemaleVoices:"Female Voices",
	TestLow:"Test Low",
	TestHigh:"Test High",
	FrequencyMarker1:"Frequency Marker #1",
	FrequencyMarker2:"Frequency Marker #2",
	VoicePrintColors:"Change Voiceprint Colors",
	SpectrumColorScale:"Spectrum Color Scale",
	OtherColors:"Other Colors",
	ColorControls:"Color Controls",
	RadioCB:"Default",
	RadioBW:"BW",
	RadioUser:"Custom",
	RestoreDefaultValues:"Restore Default Values",
	SaveReturnForm:"Save & Return To Form",
	MaleMean:"Male - Mean and Std Dev",
	FemaleMean:"Female - Mean and Std Dev",
	AverageFO:"Average FO (Hz)",
	Jitter:"Jitter (%)",
	AverageSPL:"Average SPL (dB)",
	Shimmer:"Shimmer(%)",
	HNRadio:"H/N Ratio (dB)",
	MouthToMicrophone:"Mouth to microphone: 3 cm",
	MinFO:"Min FO (Hz)",
	MaxFO:"Max FO (Hz)",
	MinSpl:"Min SPL (dB)",
	MaxSpl:"Max SPL (dB)",
	MaxPhonation:"Max Phonation Time (sec)",
	STime:"S Time (sec)",
	ZTime:"Z Time (sec)",
	SZRatio:"S/Z Ratio",
	SplRange:"SPL Range (dB)",
	FORange:"FO Range (semitones)",
	DDKRate:"DDK Rate (per second)",
	Airflow:"Airflow (Iter/sec)",
	Soft:"Soft",
	Comfortable:"Comfortable",
	Loud:"Loud",
	IntraoalPressure:"Intraoral Pressure (cm H2D)",
	ShowValues:"Show Values",
	ReadFile:"Read File",
	SaveFile:"Save File",
	MouthToMicroDistance:"Mouth to Microphone Distance",
	Cm:"cm",
	MicrophoneDigitizer:"Microphone & Digitizer",
	RadioStandard:"Standard",
	RadioHighDynamic:"High Dynamic Range",
	RecordingTriggerSpl:"Recording Trigger SPL",
	NormalLimits:"Normal Limits (Deviation Sharing on Record Form)",
	Sigma:"Sigma",
	RecordFormConfiguration:"Record Form Configuration",
	AerodynamicMeasures:"Aerodynamic Measures",
	SpeechSpectrogram:"Speech Spectrogram",
	RecordingTime:"Recording Time (In seconds)",
	Ten:"10",
	Fifteen:"15",
	Twenty:"20",
	TwentyFive:"25",
	Thirty:"30",
	Forty:"40",
	SplCalibration:"SPL Calibration",
	Start:"Start",
	Finish:"Finish",
	CalibratingLoad:"Calibrating...Please wait...",
	CalibrationMsg:"We need to do a system microphone calibration.",
	CalibrationMsgDesc1:"Calibrate your hand-held microphone by keeping it approximately six inches away from your lips.",
	CalibrationMsgDesc2:"Say and hold an AH sound as you would in normal speaking for four to five seconds at the highest comfortable volume you can manage.",
	CalibrationMsgDesc3:"Click Start to begin the calibration and Finish to end it.",
	NameValidationMsg1:"Please enter a file name",
	NameValidationMsg2:"Only alphanumeric characters allowed",
	NamePlaceholder:"Enter file name",
	Confirm:"Confirm",
	EfpePopupComfirmMsg1:"This figure already exists. Do you wish to replace it?",
	EfpePopupComfirmMsg2:"Are you sure you want to store the audio?",
	Yes:"Yes",
	No:"No",
	RecallFrom:"Recall From",
	StoreTo:"Store To",
	VocalRange:"Vocal Range",
	EFPCol1:"File",
	EFPCol2:"Voiceprint Label",
	EFPColSub:"F",
	EFPColSub1:"0",
	Estill:"Estill",
	VoicePrint:"Voiceprint",
	UpperOwnVoice:"Upper (Own Voice) Window",
	TimeSpan:"Time Span",
	Sec:"sec",
	PitchTarget:"Pitch Target",
	PitchRange:"Pitch Range",
	HarmonicLevelTarget:"Harmonic Level Target",
	AvgSplTargetErrorMsg:"Avg SPL target is insufficient",
	AvgSplTargetErrorMsgDesc:"SPL Target has been set to default. You can change it in the settings.",
	SPLTarget:"SPL Target",
	SPLRange:"SPL Range",
	TwangFreqRange:"Twang Freq Range",
	TwangLevelTarget:"Twang Level Target",
	percentIcon:"%",
	ClaritySettings:"Clarity Settings",
	SettingsUnavailable:"Settings unavailable.",
	BrightnessSettings:"Brightness Settings",
	LoudnessSettings:"Loudness Settings",
	PitchSettings:"Pitch Settings",
	InfoTitle:"Virtual Voice Trainer",
	InfoContent:"Own voice recording saved in VVT History. Click 'Recall Own Voice' to recall VVT History.",
	Error:"Error",
	NoAudioFound:"Could not find audio.",
	ExerciseNotAvailable:"Exercise unavailable.",
	ExerciseNotAvailableDescription:"does not have settings.",
	Logout:"Logout",
	ExitFullScreen:"Exit Fullscreen",
	FullScreen:"Fullscreen",
	Exercise:"Training & Testing",
	Training:"Training",
	Testing:"Testing",
	Library:"Library",
	Loading:"Loading...",
	Both:"Both",
	CaptureScreenshot:"Capture Screenshot",
	ScreenRecord:"Screen Record",
	CamOn:"Turn Cam ON",
	CamOff:"Turn Cam OFF",
	ErrorLoadScript:"Failed to load script.",
	BufferTransfer:"Which audio do you want to see in a single view?",
	Audio:"Audio",
	WhatToDo:"What would you like to do?",
	WhereToPlay:"Where would you like to play this recording?",
	RenameFile:"What would you like to name your file?",
	PleaseFileName:"Please enter a file name",
	PleaseSelectSaveOption:"Please select a save option",
	PleasePositionName:"Please select an option",
	EnterFileName:"Enter a file name",
	EnterExamName:"Enter exam name",
	AlphaAllowed:"Only alphanumeric characters allowed",
	SaveRecording:"Would you like to save this recording?",
	SpectogramFile:"Spectogram",
	PowerSpectrumFile:"PowerSpectrum",
	ClarityFile:"Clarity",
	ClarityHNRFile:"ClarityHNR",
	PitchFile:"Pitch",
	LoudnessFile:"Loudness",
	BrightnessFile:"Brightness",
	BrightnessTwangoFile:"Twango",
	WaveformFile:"Waveform",
	Capture:"Capture",
	screenRecordingFile:"ScreenRecording",
	SelectExam: "Select an exam",
	AddExam: "Add Exam",
	Exam: "Exam",
	TypeExamName: "Type exam name...",
	ActionLabel: "Action",
	F0Label: "F0",
	SplitViewSwitch: "Switch to Split View",
	PageInactive: "Page was not active",
	PageRefreshed: "The page was refreshed due to inactivity.",
	ProtocolModeOff: "You are about to exit Protocol Mode.",
	CompareWorkouts:"Training protocol requires split view. Do you want to save your recording before switching?",
	ProtocolSwitchView:"Training protocol requires split view, Would you like to continue?",
	MyLibrary:"My Library",
	DateCreated:"Created on",
	ArchivedBy:"Archived by",
	SubscriptionStart:"Subscription Starts",
	SubscriptionEnd:"Subscription Ends",
	ActivePlan:"Active Plan",
	PaymentMode:"Payment Mode",
	LoggedOut:"Logged Out",
	LoggedOutMsg:"You have been logged out.",
	InvalidLoginDetails:"Invalid login details. Kindly check your username/password.",
	Login:"Login",
	ProtocolMode: "Protocol mode is ",
	TurnOffProtocol:"Click to turn it off.",
	TurnOnProtocol:"To turn it on, choose an exercise from Training & Testing.",
	Position:"Position",
	FileSaveFor:"The file you are about to save is for",
	AudioAlreadyExists:"An audio already exists for this exercise, would you like to replace it?",
	Proceed:"Would you like to proceed?",
	SelectExamFileSave:"Please select the exam for which you would like to save this audio.",
	SaveProtocolAudio:"You are about to save a protocol audio.",
	PlayUpper:"Play on Upper canvas.",
	PlayLower:"Play on Lower canvas.",
	Goodbye:"Goodbye",
	AllAnswered:"All Answered",
	Delete:"Delete",
	DeletePopConfirmTitle:"Are you sure to delete?",
	DeleteFileSuccess:"File deleted successfully.",
	DeleteFileError:"Failed to delete file.",
	RenameFileError:"Failed to rename file.",
	SaveAudioToProtocol:"The recorded audio file will be lost, would you like to save the audio?",
	PlayHighPitch:"Play High Pitch",
	PlayLowPitch:"Play Low Pitch",
	SaveTo:"Save To",
	SaveToLocalSystem:"Local System",
	SaveToLibrary:"My Library",
	SaveToExam:"Protocol Exam",
	LibraryTabHeader1:"Files",
	LibraryTabHeader2:"Exams",
	Sensitivity:"Sensitivity level",
	High:"High",
	Low:"Low",
	BackHome:"Back to Home",
	Theme:"Theme",
	Background:"Background",
	ChoosePicture:"Choose your picture",
	CustomBgInstructions:"Click or drag an image to this area to upload",
	PowerSpectrumAndResonance:"P/R",
	Markers:"Markers: ",
	LetterB: 'B',
	LetterA: 'A', 
	RegistrationTitle:"Registration",
	FirstName:"First Name",
	LastName:"Last Name",
	ContactNo:"Contact No",
	Email:"Email",
	Address:"Address",
	Password:"Password",
	ConfirmPassword:"Confirm Password",
	Country:"Country",
	State:"State",
	City:"City",
	ZipCode:"Zip/Post Code",
	RegisterBtn:"Create an account",
	FirstNameErrorMsg:"First name is required.",
	LastNameErrorMsg:"Last name is required.",
	ContactNoErrorMsg:"Contact no. is required.",
	EmailErrorMsg:"Email is required.",
	EmailErrorMsgValid:"Email is not valid.",
	AddressErrorMsg:"Address is required.",
	ConfirmPasswordRegistration: "Confirm password is required.",
	PasswordNotMatch:"The two passwords that you entered do not match.",
	PasswordValidation:"Password length should be minimum 8 characters. It must contain at least 1 uppercase letter, 1 lowercase letter, a number, any special character and no spaces.",
	TermsAndPrivacy1:"I agree to the ",
	TermsAndPrivacy2:"Terms & Privacy Policy",
	TermsAndPrivacyModalHeader:"Terms and Privacy Policy",
	SelectCountry:"Select Country first",
	MyProfile:"My Profile",
	LoginTitle:"Login",
	AdminLoginTitle:"Admin Login",
	Username:"Username",
	SignUp:"Sign up",
	ForgotPassword:"Forgot Password?",
	UsernameErrorMsg:"Username is required.",
	PasswordErrorMsg:"Password is required.",
	NoAccountSignUpText:"Don't have an account?",
	ForgotPsdHeader: "Forgot your password? Don't Panic!",
	SendInstructionsBTN: "Send Instructions",
	RememberMe:"Remember Me",
	Role: 'Role',
	UserType: 'User Type',
	PlanType: 'Plan Type',
	RegisteredDate: 'Registered Date',
	SubscriptionInterval: 'Subscription Interval',
	Status: 'Status',
	CreatedBy: 'Created by',
	AddUser: 'Add User',
	AddSubscriber: 'Add Subscriber',
	ViewSubscriber: 'View Subscriber',
	RoleErrorMsg: "Please choose the role type",
	UserErrorMsg: "Please choose the user type",
	PlanErrorMsg: "Please choose the plan type",
	CreateNewAccount: "Create a new account",
	Edit: "Edit",
	View: "View",
	EditAccount: "Edit Account",
	ViewAccount: "View Account",
	Submit: "Submit",
	UserManager: "User Manager",
	UserSubscribers:"Subscriber Manager",
	AdminDashboard: "Admin Dashboard",
	ChangePassword:'Change Password',
	UpdatePassword:'Update Password',
	GoToFeedbackSuite:"Go to Feedback Suite",
	GoBackToAdminPortal:"Go to Admin portal",
	OldPassword:"Old Password",
	NewPassword:"New Password",
	ConfimNewPassword:"Confirm New Password",
	BackToLogin: "Back to login",
	LogoutSuccess: "You have successfully logged out.",
	LoginSuccess: "You have successfully logged in.",
	RegistrationSuccess: "Registration successful.",
	TokenExpired: ["Oops! It seems like your session has expired. Please log in again to continue enjoying our application."],
	ResetPasswordTokenExpired: "Oops! It seems like the session for resetting your password has expired.",
	ResetPassword: "Reset Password",
	ResetPasswordSessionTimeout: "Session timed out!",
	ConfirmNewPasswordErrorMsg:"Confirm new Password",
	VoiceInsiderHeader:"Voiceprint Insider Subscription",
	VoiceInsiderSubHeader:"Dates Offered: January 22nd, 2023 - April 24, 2023",
	VoiceInsiderDiscriptionPara1:"Gain unparalleled access to the newest version of our world renowned	spectral analysis software, Estill Voiceprint, with the Voiceprint Insider Subscription. Enjoy the thrill of being at the forefront of change, testing	and experiencing our new browser based software at a discounted rate.	You'll be the first to use our beta releases, and groundbreaking technology	before they hit the mainstream market. Join our community of leaders in voice training & rehabilitation and help shape the future of our most popular program.",
	VoiceInsiderDiscriptionPara2:"Subscription includes full access to all features as they are rolled out	and is valid for one year following purchase.",
	GeneralPublicHeader:"General Public",
	VoiceTrainerHeader:"Estill Voice Trainers",
	ChoosePlanbutton:"Choose This Plan",
	PaymentSuccess:"Payment Successful",
	RegistrationSuccessfull:"Successfully registered!",
	Trainer: 'Trainer',
	EMCI: 'EMCI',
	Subscriber: 'Subscriber',
	Admin: 'Admin',
	GeneralPublic: 'General Public',
	EVI: 'EVI',
	EMT:"Estill Master Trainer",
	EFP:"Estill Figure Proficiency",
	EFPAbbr:"EFP",
	EMTAbbr:"EMT",
	Active: 'ACTIVE',
	Inactive: 'INACTIVE',
	ChoosePaymentGateway:"Choose your payment gateway.",
	NewNotifications:"You got new notifications",
	NoNewNotifications:"No new notifications",
	MarkAsRead:"Mark as read",
	GetFeature:"Get feature(s)",
	GettingLatestFeatures:"Getting the latest feature(s) for you...",
	PageNotFound:"Sorry, the page you visited does not exist.",
	NewFeatureAvailable:"New feature available in Voiceprint!",
	MyNotifications:"My notifications",
	CurrentRecording: "Current Recording:",
	StorageSpaceErrorMessage: "Not enough storage space available.",
	MB: "MB",
	AvailableStorage: "Available Storage:",
};
//COMBINED CONSTANTS + RESPONSE MESSAGES
const allConstants = { ...responseMessages, ...constants };

const AUDIO_SETTINGS = {
	SAMPLE_RATE: 48000,
    FFT_SIZE: 4096,
    BUFFER_SIZE: 2048,
    ZERO_REF_SIZE: 2048,
	TIME_CHUNKS_DIVISOR: 2048,

	// new LNM audio settings
	rawAudioBufferLength: 2048,
	sampleRate: 48000,
	fftSize: 4096,
	defaultMaximumFrequency: 5000, // Maximum frequency to display in the spectrogram
	interpolationFactor: 12, // Using this value to interpolate between adjacent data points to match playback time
	maxInterpolationFactor: 10, // Maximum value for interpolation factor
	defaultRecordingLength: 90000, // Length of the recording in milliseconds
};

const SPECTROGRAM_COLORS = [
	{ value: 0,   red: 0, green: 255, blue: 0,   opacity: 1.0 },  // Peak green
	{ value: -7,  red: 0, green: 255, blue: 15,  opacity: 1.0 },  // Near-peak green
	{ value: -15, red: 0, green: 255, blue: 45,  opacity: 1.0 },  // Strong bright green
	{ value: -22, red: 0, green: 250, blue: 75,  opacity: 1.0 },  // Strong green-blue
	{ value: -30, red: 0, green: 240, blue: 105, opacity: 1.0 },  // Medium green-blue
	{ value: -37, red: 0, green: 210, blue: 170, opacity: 1.0 },  // Medium cyan
	{ value: -45, red: 0, green: 170, blue: 245, opacity: 1.0 },  // Light blue
	{ value: -52, red: 0, green: 130, blue: 230, opacity: 1.0 },  // Medium-light blue
	{ value: -60, red: 0, green: 80,  blue: 215, opacity: 1.0 },  // Medium blue
	{ value: -65, red: 0, green: 40,  blue: 200, opacity: 1.0 },  // Medium-dark blue
	{ value: -70, red: 0, green: 0,   blue: 0,   opacity: 1.0 }   // Black
  ];
  
const SIGNAL_DISPLAY_WIDTH = 1200;

const CANVAS_CACHE_ACTIVE = true;

export {
	constants,
	responses as Messages,
	apiEnvs,
	codes,
	dateFormats,
	defaultColors,
	responseMessages,
	allConstants,
	AUDIO_SETTINGS,
	SPECTROGRAM_COLORS,
	SIGNAL_DISPLAY_WIDTH,
	CANVAS_CACHE_ACTIVE
};