/*---------------------------------------------------------------------------
File Name: logic.js
Author: Akshay Lotlikar
Modified On: 05/02/2024
Description: The Logic page represents the code of all common logical functions
used in other pages.
---------------------------------------------------------------------------*/
import { isEqual } from 'lodash'
import { Note } from 'tonal'
import { constants, defaultColors, AUDIO_SETTINGS } from '../../constants';
import URLs from '../../components/urls';
import html2canvas from 'html2canvas';
import { Upload, notification } from 'antd';
import { COOKIE, createCookie, getCookie } from '../../cookie';

//CHECK BROWSER
const getBrowserName = () => {
	let sBrowser, sUsrAg = navigator.userAgent;
	if (sUsrAg.indexOf("Firefox") > -1) {
		sBrowser = "Mozilla Firefox";
		// "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
	} else if (sUsrAg.indexOf("SamsungBrowser") > -1) {
		sBrowser = "Samsung Internet";
		// "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
	} else if (sUsrAg.indexOf("Opera") > -1 || sUsrAg.indexOf("OPR") > -1) {
		sBrowser = "Opera";
		// "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
	} else if (sUsrAg.indexOf("Trident") > -1) {
		sBrowser = "Microsoft Internet Explorer";
		// "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
	} else if (sUsrAg.indexOf("Edge") > -1) {
		sBrowser = "Microsoft Edge";
		// "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
	} else if (sUsrAg.indexOf("Chrome") > -1) {
		sBrowser = "Google Chrome or Chromium";
		// "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
	} else if(/iPad|iPhone|iPod/.test(sUsrAg) || (sUsrAg.indexOf("Safari") > -1 && navigator.maxTouchPoints > 1)) {
		sBrowser = "iOS";
	} else if (sUsrAg.indexOf("Safari") > -1) {
		sBrowser = "Apple Safari";
		// "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
	} else {
		sBrowser = "unknown";
	}
	return sBrowser;
}
//GET CURRENT URL
const setUserDetails = () => {
	if(window.location.href.includes(URLs.clientExt) || window.location.href.includes(URLs.prodExt)) {
		localStorage.setItem('username', 'Estill User')
		localStorage.setItem('user_id', 1)
	} else if(window.location.href.includes(URLs.devExt) || window.location.href.includes(URLs.localExt)) {
		localStorage.setItem('username', 'OPSPL User')
		localStorage.setItem('user_id', 2)
	}
}
const getRecordingTime = () => {
	let time = getCookie('time')
	if(!time) {
		time = 10
		createCookie(COOKIE.Time, 10, null, "/");
	}
	return time
}
//GET AUDIO CONTEXT
const getAudioContext = () => {
	return new AudioContext({
		latencyHint: "interactive",
		sampleRate : AUDIO_SETTINGS.SAMPLE_RATE,
	});
}
const getPixelValue = (ac, height) => {
	let yb = 0;
	let md = height === 440 ? 1 : 2
	const ranges = [
    { min: 55, max: 110, formula: (ac) => Math.ceil(height - (((ac - 55) * (1.6 / md)))) },
    { min: 110, max: 220, formula: (ac) => Math.ceil(height - (((ac - 110) * (0.8 / md)) + (88 / md))) },
    { min: 220, max: 440, formula: (ac) => Math.ceil(height - (((ac - 220) * (0.4 / md)) + (176 / md))) },
    { min: 440, max: 880, formula: (ac) => Math.ceil(height - (((ac - 440) * (0.2 / md)) + (264 / md))) },
    { min: 880, max: 1760, formula: (ac) => Math.ceil(height - (((ac - 880) * (0.1 / md)) + (352 / md))) },
    { min: 1760, max: Infinity, formula: (ac) => height + 2 },
  ];
	const range = ranges.find((range) => ac > range.min && ac <= range.max);
	if (range) {
    yb = range.formula(ac);
  }
	return yb;
}
//GENERATE PITCH FROM FREQUENCY
const noteFromFrequency = (frequency) => {
	const pitchNames = ['C','C#','D','D#','E','F','F#','G','G#','A','A#','B'];
	const baseFrequency = 16
	if(frequency >= baseFrequency) {
		const octave = Math.floor(Math.log2(frequency/baseFrequency));
		let pitchIndex = Math.round(12 * Math.log2(frequency/baseFrequency) % 12);
		// Handle case where pitch index equals 12
		if (pitchIndex === 12) pitchIndex = 0
		return pitchNames[pitchIndex] + octave
	}
}
//CORRELATE
const autoCorrelate = (buf, sampleRate) => {
	// Implements the ACF2+ algorithm
	var SIZE = buf.length;
	var rms = buf.reduce((accumulator, current) => accumulator + (current*current), 0)

	rms = Math.sqrt(rms/SIZE);
	if (rms<0.01) // not enough signal
		return -1;

	var r1=0, r2=SIZE-1, thres=0.2;
	for (let i=0; i<SIZE/2; i++)
		if (Math.abs(buf[i])<thres) { r1=i; break; }
	for (let i=1; i<SIZE/2; i++)
		if (Math.abs(buf[SIZE-i])<thres) { r2=SIZE-i; break; }
		
	buf = buf.slice(r1,r2);
	SIZE = buf.length;
	
	var c = new Array(SIZE).fill(0);
	for (let i=0; i<SIZE; i++)
		for (let j=0; j<SIZE-i; j++)
			c[i] = c[i] + buf[j]*buf[j+i];
			
			var d=0; while (c[d]>c[d+1]) d++;
			var maxval=-1, maxpos=-1;
			for (var i=d; i<SIZE; i++) {
				if (c[i] > maxval) {
					maxval = c[i];
					maxpos = i;
				}
			}
	var T0 = maxpos;

	var x1=c[T0-1], x2=c[T0], x3=c[T0+1];
	var a = (x1 + x3 - 2*x2)/2;
	var b = (x3 - x1)/2;
	if (a) T0 = T0 - b/(2*a);

	return sampleRate/T0;
}
//FLATTENING THE ARRAY
const flattenArray = (channelBuffer, recordingLength) => {
	var result = new Float32Array(recordingLength);
	var offset = 0;
	channelBuffer.forEach((buffer) => {
		result.set(buffer, offset);
		offset += buffer.length;
	});
	return result;
}
//INTERLEAVE CHANNELS
const interleave = (leftChannel, rightChannel) => {
	var length = leftChannel.length + rightChannel.length;
	var result = new Float32Array(length);

	var inputIndex = 0;

	for (var index = 0; index < length;) {
		result[index++] = leftChannel[inputIndex];
		result[index++] = rightChannel[inputIndex];
		inputIndex++;
	}
	return result;
}
//BYTE CONVERSION
const writeUTFBytes = (view, offset, string) => {
	for (var i = 0; i < string.length; i++) {
		view.setUint8(offset + i, string.charCodeAt(i));
	}
}
//CREATE ARRAY BUFFER
const getArrayBuffer = async (url, context) => {
	const response = await fetch(url)
	const arrayBuffer = await response.arrayBuffer()
	const audioBuffer = await context.decodeAudioData(arrayBuffer)
	return audioBuffer
}
//CONVERT BLOB TO INT16ARRAY
const convertBlobToInt16Array = async (blob) => {
	const Int16ArrayBuffer = []
	await new Response(blob).arrayBuffer()?.then((arrayBuffer) => {
		let b316 = [...new Int16Array(arrayBuffer)];
		let sd = []
		for(let re1 = 0; re1 < b316.length; re1++){
			sd[re1] = b316[re1] / 32768
		}
		sd = sd?.slice(22)
		const bufLength = sd?.length / AUDIO_SETTINGS.BUFFER_SIZE

		let se = []
		let offset = 0
		for(let re2 = 0; re2 < bufLength; re2++) {
			for(let re3 = 0; re3 < AUDIO_SETTINGS.BUFFER_SIZE; re3++) {
				se[re3] = sd[offset + re3]
			}
			Int16ArrayBuffer.push(se)
			se = [];
			offset = offset + AUDIO_SETTINGS.BUFFER_SIZE
		}
	})
	return Int16ArrayBuffer;
}
//DEBOUNCE FN FOR DELAY
const debounceDelay = (cb, delay = 100) => {
	let timeout
	return (...args) => {
		clearTimeout(timeout)
		timeout = setTimeout(() => {
			cb(...args)
		}, delay);
	}
}
//CHECK IF OBJECT CONTAINS SAME VALUES
const hasObjectsChanged = (obj1, obj2) => {
	let flag = false
	if(JSON.stringify(obj1) === JSON.stringify({}) && JSON.stringify(obj2) !== JSON.stringify({})) {
		flag = true
	} else {
		if(!isEqual(obj1, obj2)) {
			flag = true
		}
	}
	return flag
}
//CONVERT PERCENTAGE TO RANGE
const gainNodeConvert = (inputIn, inputOut, outputIn, outputOut, percentage) => {
	let xMax = outputIn;
	let xMin = outputOut;
	let yMax = inputIn;
	let yMin = inputOut;
	let percent = (percentage - yMin) / (yMax - yMin);
	let outputX = percent * (xMax - xMin) + xMin;
	return outputX
}
//CALCULATE LENGTH OF CHART
const calcTimeLength = (timeframe) => {
	let signalSize = 4
	let segmentsCount = 1024
	let oL= 512;
	let interval = 40; // Used for plotting duration for scheduling static plots

	if(timeframe === 40) {
		signalSize = 1024;
		segmentsCount = 2;
		oL=1024;
		interval = 10;
	} 
	else if(timeframe === 20) {
		signalSize = 512;
		segmentsCount = 4;
		interval = 10;
	} 
	else if(timeframe === 10) {
		// signalSize = 256;
		// segmentsCount = 8;
		// oL = 256;
		// interval = 40;
		signalSize = 256;
		segmentsCount = 8;
		oL = 256;
		interval = 40;
	}

	return {
		signalSize: signalSize,
		segmentsCount: segmentsCount,
		oL:oL,
		interval:interval,
	}
};
//DRAW FREQUENCY LINES ON CHART
const drawFrequencies = (canvasHeight, min, max) => {
	let lineObject = {}
	lineObject.lower = canvasHeight - Math.round(parseInt(min) / 12)
	lineObject.upper = canvasHeight - Math.round(parseInt(max) / 12)
	return lineObject
}
//GET VIEW FOR GENERATING WAV FILE
const getView = (leftChannel, rightChannel, recordingLength) => {
	//TRIM LOGIC
	let trimData = getRecordingLength(
		recordingLength, 
		Number(localStorage.getItem('startTrim')) || 0, 
		Number(localStorage.getItem('endTrim')) || 0, 
		leftChannel,
		rightChannel
	)
	// Flattening multi-dimensional array to singal dimension array maintaining Float32 resolution
	var leftBuffer = flattenArray(trimData?.leftChannel, trimData?.length);
	var rightBuffer = flattenArray(trimData?.leftChannel, trimData?.length);

	// Interleave channels
	var interleaved = interleave(leftBuffer, rightBuffer);

	// Creating wav file
	var buffer = new ArrayBuffer(44 + interleaved.length * 2);
	var view = new DataView(buffer);

	// RIFF chunk descriptor
	writeUTFBytes(view, 0, "RIFF");
	view.setUint32(4, 32 + interleaved.length * 2, true);
	writeUTFBytes(view, 8, "WAVE");
	// FMT sub-chunk
	writeUTFBytes(view, 12, "fmt ");
	view.setUint32(16, 16, true); // chunkSize
	view.setUint16(20, 1, true); // wFormatTag
	view.setUint16(22, 2, true); // wChannels: stereo (2 channels)
	view.setUint32(24, AUDIO_SETTINGS.SAMPLE_RATE, true); // dwSamplesPerSec
	view.setUint32(28, AUDIO_SETTINGS.SAMPLE_RATE * 4, true); // dwAvgBytesPerSec
	view.setUint16(32, 4, true); // wBlockAlign
	view.setUint16(34, 16, true); // wBitsPerSample
	// data sub-chunk
	writeUTFBytes(view, 36, "data");
	view.setUint32(40, interleaved.length * 2, true);

	// write the PCM samples
	var index = 44;
	var volume = 1;
	var s;
	interleaved.forEach((value) => {
		s = Math.max(-1, Math.min(1, value));
		view.setInt16(index, s < 0 ? s * 0x8000 * volume : s * 0x7fff * volume, true);
		index += 2;
	});
	return view
}
//CALCULATE RECORDING LENGTH
const getRecordingLength = (length, start, end, leftChannel, rightChannel) => {
	let leftC = []
	let rightC = []
	let eachDataTime = parseInt(getRecordingTime()) / length
	let trimReqStart = Math.ceil(start / eachDataTime)
	let trimStartDataChunks = Math.ceil(trimReqStart / AUDIO_SETTINGS.TIME_CHUNKS_DIVISOR)
	let trimStartRecLength = trimStartDataChunks * AUDIO_SETTINGS.TIME_CHUNKS_DIVISOR

	let trimReqStop = Math.ceil(end / eachDataTime)
	let trimStopDataChunks = Math.ceil(trimReqStop / AUDIO_SETTINGS.TIME_CHUNKS_DIVISOR)
	let trimStopRecLength = trimStopDataChunks * AUDIO_SETTINGS.TIME_CHUNKS_DIVISOR

	let newRecordingLength = length - (trimStartRecLength + trimStopRecLength)

	let count = 0;
	for(let i = 0 + trimStartDataChunks; i < (leftChannel?.length - trimStopDataChunks); ++i) {
		leftC[count] = leftChannel[i];
		rightC[count] = rightChannel[i];
		count = count + 1
	}
	
	return{
		length: newRecordingLength,
		leftChannel: leftC,
		rightChannel: rightC,
	}
}
//GET NOTES AND FREQUENCIES
const getFrequenciesAndNote = (min,max,step) => {
	let frequencyArr = []
	for(let i = min; i <= max; i+=step) {
		frequencyArr.push(i)
	}
	frequencyArr = frequencyArr.map((item) => { return { freq: item, note: Note.fromFreq(item) } })
	return frequencyArr
}
//GET FREQUENCY AND OCTAVE
const getFrequenciesAndOctave = (target) => {
	let frequencyArr = []
	let frequencyObj = []

	let fmax = Math.round(target * 1.414)
	let fmin = Math.round(target / 1.414)
	let range = fmax - fmin

	for(let i = 1; i <= 3; i+=0.1) {
		let iFixed = parseFloat(i.toFixed(1))
		if(iFixed === 1) {
			frequencyArr.push({ range: range, fmax: fmax, fmin: fmin, octave: iFixed })
			frequencyObj.push(range)
		} else {
			let new_fmax = Math.round((range * iFixed) + range)
			let new_fmin = Math.round(new_fmax / Math.pow(2, iFixed))
			let new_range = new_fmax - new_fmin
			frequencyArr.push({ range: new_range, fmax: new_fmax, fmin: new_fmin, octave: iFixed })
			frequencyObj.push(new_range)
		}
	}
	return { frequencyArr, frequencyObj }
}
//GET FFT MULTIPLIER
const getMultiplier = () => {
	let time = parseInt(getRecordingTime())
	if(time === 10) return 8
	else if(time === 20) return 4
	else if(time === 40) return 2
}
//GET Q FACTOR
const getQFactor = (target, bandwidth) => {
	let fh = target + (bandwidth / 2)
	let fl = target - (bandwidth / 2)

	let n = Math.log10(fh / fl) / Math.log10(2)

	let q_factor = Math.sqrt(Math.pow(2, Math.ceil(n))) / (Math.pow(2, Math.ceil(n)) - 1)
	return q_factor
}
//X-AXIS LINE PLOTTING
const plotXAxis = (canvas, parentWidth, chartType) => {
	// Get the canvas element from the DOM
	const axis = document.getElementById(canvas);
	const ctx = axis.getContext('2d');
	// Set the canvas width and height to 1000 pixels and 100 pixels respectively
	axis.width = parentWidth;
	axis.height = '50';
	// Set the line width and color
	ctx.lineWidth = 2;
	ctx.strokeStyle = 'white';
	ctx.fillStyle = "white";
	ctx.font = '12px Roboto';
	ctx.shadowColor = "#313131";
	ctx.shadowBlur = 5;

	// Get the selected option value
	const selectedOption = getRecordingTime()?.toString()

	// Determine the number of parts to divide the line into based on the selected option
	let numParts = 6;
	// Calculate the part width
	const partWidth = chartType === 'powerspectrum' ? 186 : 172;

	// Clear the canvas
	ctx.clearRect(0, 0, axis.width, axis.height);

	// Draw the X-axis line
	ctx.beginPath();
	ctx.moveTo(0, axis.height / 2);
	ctx.lineTo(axis.width, axis.height / 2);
	ctx.stroke();

	// Draw the vertical lines to divide the X-axis line into parts
	for (let i = 1; i < numParts; i++) {
		const x = i * partWidth;
		ctx.beginPath();
		ctx.moveTo(x, axis.height / 2 - 5);
		ctx.lineTo(x, axis.height / 2 + 5);
		ctx.stroke();

		// Plot the number in multiples of 2
		let num = '';
		if(chartType === 'powerspectrum') num = `${i} kHz`
		else {
			if(selectedOption === constants.Ten) num = `${i * 2} sec`
			else if(selectedOption === constants.Twenty) num = `${i * 4} sec`
			else if(selectedOption === constants.Forty) num = `${i * 8} sec`
		}
		ctx.fillText(num, x - 5, axis.height / 2 + 20);
	}
}
//Y-AXIS LINE PLOTTING
const plotYAxis = (canvas, parentHeight, chartType) => {
	// Get the canvas element and create a rendering context
	const axis = document.getElementById(canvas);
	const ctx = axis.getContext('2d');
	// Set canvas height
	axis.height = parentHeight
	axis.width = '80'
	// Set the line style
	ctx.strokeStyle = 'white';
	ctx.fillStyle = "white";
	ctx.lineWidth = 2;
	ctx.font = '12px Roboto';
	ctx.shadowColor = "#313131";
	ctx.shadowBlur = 5;

	let details = getStrokeDetails(chartType);
	// Calculate the part height
	const partHeight = axis.height / details?.strokes;

	// Clear the canvas
	ctx.clearRect(0, 0, axis.width, axis.height);

	// Draw the Y-axis line
	ctx.beginPath();
	ctx.moveTo(axis.width / 2, 0);
	ctx.lineTo(axis.width / 2, axis.height);
	ctx.stroke();

	// Draw the horizontal lines to divide the Y-axis line into parts and plot the numbers
	for (let i = 1; i < details?.strokes; i++) {
		const y = i * partHeight;
		ctx.beginPath();
		ctx.moveTo(axis.width / 2 - 5, y);
		ctx.lineTo(axis.width / 2 + 5, y);
		ctx.stroke();
		// Plot the number in multiples of 2
		const num = details?.values[details?.values?.length - i];
		ctx.fillText(`${num.toString()}${details?.unit}`, axis.width / 2 - 35, y + 5); //CONDITION IS TEMPORARY
	}
}
//UPDATE AXES ON PLAYBACK SLIDER CHANGE
const updateAxes = (axisX, axisY, width, height, chart) => {
	plotXAxis(axisX, width, chart)
	plotYAxis(axisY, height, chart)
}
//GET NUMBER OF STROKES FOR EACH CHART
const getStrokeDetails = (chart) => {
	if (chart === 'spectrogram') {
		return { strokes: 5, values: [1, 2, 3, 4], unit: ' kHz' }
	} else if(chart === 'pitch') {
		return { strokes: 5, values: [55, 110, 220, 440, 880], unit: ' Hz' }
	} else if(chart === 'spl') {
		return { strokes: 5, values: [70, 80, 90, 100], unit: ' dB' }
	} else if(chart === 'powerspectrum') {
		return { strokes: 5, values: [-30, 0, 30, 60, 90], unit: ' dB' }
	} else if(chart === 'pitchmelody') {
		return { strokes: 3, values: [100, 200], unit: ' Hz' }
	} else if(chart === 'color') {
		return { strokes: 4, values: [0, 1, 2, 3], unit: ' Hz' }
	} else if(chart === 'loudness') {
		return { strokes: 5, values: [60, 70, 80, 90, 100], unit: ' dB' }
	}
}
//DOWNLOAD AUDIO TO SYSTEM
const downloadAudio = (audio, fileName) => {
	if (audio) {
		let audioURL = URL.createObjectURL(audio);
		let link = document.createElement("a");
		link.href = audioURL;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
	}
}
//GET BLOB FROM AUDIO URL
const fetchAudioAsBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
	return blob
}
//DRAW VERTICAL LINE CURSOR
const drawVerticalLine = (event, canvas, context, recordOrPlayback, bufferLength = 0) => {
	if(!recordOrPlayback&&bufferLength > 0 && ((event.offsetX - 3) <= bufferLength)) {
		context.clearRect(0, 0, canvas.width, canvas.height);
		context.strokeStyle = 'white';
		context.lineWidth = 1;
		context.beginPath();
		context.moveTo(event.offsetX, 0);
		context.lineTo(event.offsetX, canvas.height);
		context.stroke();
	}
}
//GET MIDI VALUE FROM FREQUENCY
const midiFromFrequency = (frequency) => {
	var noteNum = 12 * (Math.log(frequency/440)/Math.log(2));
	return Math.round(noteNum) + 69;
}
//TAKE SCREENSHOT
const captureScreenshot = (id, fileName, extension) => {
	const element = document.getElementById(id);
	html2canvas(element).then((canvas) => {
		const imgData = canvas.toDataURL('image/png');
		const link = document.createElement('a');
		link.href = imgData;
		if(fileName) link.download = fileName + extension;
		else link.download = "download.png";
		link.click();
	});
};
//DUMMY REQUEST
const dummyRequest = ({ file, onSuccess }) => {
	setTimeout(() => {
		onSuccess("ok");
	}, 0);
};
//CHECK TYPE OF AUDIO FILE BEING LOCALLY UPLOADED
const beforeUpload = (file, type) => {
	const isValid = 
		type === 'audio' ? 
		file.type === "audio/wav" : 
		(file.type === "image/png" || file.type === "image/jpg" || file.type === "image/jpeg");
	if (!isValid) {
		notification.error({
			message: "Error",
			description: `${file?.name} ${type === 'audio' ? constants.NotWavFile : constants.NotPngJpgFile}`,
		});
	}
	return isValid || Upload.LIST_IGNORE;
};
//PLOT FREQUENCY LINES
const plotFrequencyLines = (canvas, context, settings, min, max, clear = true, vertical = false) => {
    if(clear) context.clearRect(0, 0, canvas?.width, canvas?.height);
    if(settings?.markers) {
        context.strokeStyle = defaultColors.FrequencyLinePlotColor;
        
        if (vertical) {
            // First vertical line
            context.beginPath();
            const x1 = gainNodeConvert(min, max, 0, canvas?.width, settings?.freq_min) - 32;
            context.moveTo(x1, 0);
            context.lineTo(x1, canvas?.height);
            context.stroke();
            
            // Second vertical line
            context.beginPath();
            const x2 = gainNodeConvert(min, max, 0, canvas?.width, settings?.freq_max) - 32;
            context.moveTo(x2, 0);
            context.lineTo(x2, canvas?.height);
            context.stroke();
        } else {
            // Original horizontal line code
            context.beginPath();
            context.moveTo(0, (canvas?.height - gainNodeConvert(min, max, 0, canvas?.height, settings?.freq_min)));
            context.lineTo(canvas?.width, (canvas?.height - gainNodeConvert(min, max, 0, canvas?.height, settings?.freq_min)));
            context.stroke();
            
            context.beginPath();
            context.moveTo(0, (canvas?.height - gainNodeConvert(min, max, 0, canvas?.height, settings?.freq_max)));
            context.lineTo(canvas?.width, (canvas?.height - gainNodeConvert(min, max, 0, canvas?.height, settings?.freq_max)));
            context.stroke();
        }
    }
};
//APPLY SPECTROGRAM COLOR
const applySpectrogramColors = (context, settings, amp, x, y) => {
	const getSpectralColor = (number) => {
		return settings?.[`spectrogram_${number}_color`] || defaultColors[`Spectrogram${number}DefaultColor`]
	}
	const getBgColor = () => {
		return settings?.chart_bg_color || defaultColors.BackgroundColorDefault
	}

  const thresholds = [
    { min: 237, max: 255, color: getSpectralColor('1') },
    { min: 218, max: 236, color: getSpectralColor('2') },
    { min: 199, max: 217, color: getSpectralColor('3') },
    { min: 180, max: 198, color: getSpectralColor('4') },
    { min: 161, max: 179, color: getSpectralColor('5') },
    { min: 142, max: 160, color: getSpectralColor('6') },
    { min: 123, max: 141, color: getSpectralColor('7') },
    { min: 104, max: 122, color: getSpectralColor('8') },
    { min: 85, max: 103, color: getSpectralColor('9') },
    { min: 66, max: 84, color: getSpectralColor('10') },
    { min: 47, max: 65, color: getSpectralColor('11') },
    { min: 28, max: 46, color: getSpectralColor('12') },
    { min: 9, max: 27, color: getBgColor() },
    { min: 1, max: 27, color: getBgColor() },
    { min: 0, max: 0, color: getBgColor() },
  ];

  for (const threshold of thresholds) {
    if (amp >= threshold.min && amp <= threshold.max) {
      context.fillStyle = threshold.color;
      break;
    }
  }
  context.fillRect(x, y, 2, 2);
};


const setDataView = (sampleCount, context) => {
	const channels = 1  // Mono audio
	const bitsPerSample = 16
	const bytesPerSample = bitsPerSample / 8
	const dataLength = sampleCount * bytesPerSample
	const sampleRate = context?.sampleRate
	const arrayBuffer = new ArrayBuffer(44 + dataLength)
	let dataView = new DataView(arrayBuffer)

	dataView.setUint8(0, 'R'.charCodeAt(0))
	dataView.setUint8(1, 'I'.charCodeAt(0))
	dataView.setUint8(2, 'F'.charCodeAt(0))
	dataView.setUint8(3, 'F'.charCodeAt(0))
	dataView.setUint32(4, 36 + dataLength, true)
	dataView.setUint8(8, 'W'.charCodeAt(0))
	dataView.setUint8(9, 'A'.charCodeAt(0))
	dataView.setUint8(10, 'V'.charCodeAt(0))
	dataView.setUint8(11, 'E'.charCodeAt(0))
	dataView.setUint8(12, 'f'.charCodeAt(0))
	dataView.setUint8(13, 'm'.charCodeAt(0))
	dataView.setUint8(14, 't'.charCodeAt(0))
	dataView.setUint8(15, ' '.charCodeAt(0))
	dataView.setUint32(16, 16, true)
	dataView.setUint16(20, 1, true)
	dataView.setUint16(22, channels, true)
	dataView.setUint32(24, sampleRate, true)
	// Fix: Correct byte rate calculation for mono
	dataView.setUint32(28, sampleRate * channels * bytesPerSample, true)
	// Fix: Correct block align
	dataView.setUint16(32, channels * bytesPerSample, true)
	dataView.setUint16(34, bitsPerSample, true)
	dataView.setUint8(36, 'd'.charCodeAt(0))
	dataView.setUint8(37, 'a'.charCodeAt(0))
	dataView.setUint8(38, 't'.charCodeAt(0))
	dataView.setUint8(39, 'a'.charCodeAt(0))
	dataView.setUint32(40, dataLength, true)
	return dataView
}

const activeGainControl= {     
	sampleRate: AUDIO_SETTINGS.sampleRate,    
	echoCancellation: false,
	noiseSuppression: true,
	autoGainControl: false,
	lowpassFilter: false,
	highpassFilter: false,
	typingNoiseDetection: false,
	typingNoiseReduction: true,
	typingNoiseCancellation: true,
	noiseReduction: true,
	noiseCancellation: true,
	googAutoGainControl: false,
	googAutoGainControl2: false,
	googEchoCancellation: false,
	googNoiseSuppression: true,
	googLowpassFilter: false,
	googHighpassFilter: false,
	googTypingNoiseDetection: false,
	googTypingNoiseReduction: true,
	googTypingNoiseCancellation: true,
	googNoiseReduction: true,
	googNoiseCancellation: true,
	mozAutoGainControl: false,
	mozAutoGainControl2: false,
	mozEchoCancellation: false,
	mozNoiseSuppression: true,
	mozLowpassFilter: false,
	mozHighpassFilter: false,
	mozTypingNoiseDetection: true,
	mozTypingNoiseReduction: true,
	mozTypingNoiseCancellation: true,
	mozNoiseReduction: true,
	mozNoiseCancellation: true,
}
export { 
	activeGainControl, applySpectrogramColors, autoCorrelate, 
	calcTimeLength, captureScreenshot, debounceDelay, 
	drawFrequencies, drawVerticalLine, downloadAudio, 
	dummyRequest, flattenArray, gainNodeConvert, 
	getArrayBuffer, getAudioContext, getBrowserName, 
	getFrequenciesAndNote, getFrequenciesAndOctave, getMultiplier, 
	getPixelValue, getQFactor, getRecordingLength, 
	getRecordingTime, getView, hasObjectsChanged, 
	interleave, midiFromFrequency, noteFromFrequency, 
	plotFrequencyLines, plotXAxis, plotYAxis, 
	setUserDetails, updateAxes, writeUTFBytes,
	setDataView, beforeUpload, convertBlobToInt16Array,
	fetchAudioAsBlob, getStrokeDetails,
}
